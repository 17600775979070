$(document).ready(function() {

    $('.slideshow').each(function() {
        var $this = $(this);
        $this.cycle({
            fx: 'fade', // choose your transition type, ex: fade, scrollUp, shuffle, etc...
            random: 1,
            speed: 2000,
            fit:1
        });
    });
});

jQuery(document).ready(function($) {
    jQuery('#mega-menu').dcMegaMenu();
});

function add_fields(link, association, content) {
    var new_id = new Date().getTime();
    var regexp = new RegExp("new_" + association, "g");
    $(link).closest("tr").before(content.replace(regexp, new_id));
}

document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
})
$(document).on('ajax:complete', function (event) {
    let request = event.detail[0]
    let msg = request.getResponseHeader("X-Message");
    let alert_type = 'alert-success';
    if (request.getResponseHeader("X-Message-Type").indexOf("error") !== -1) {
        alert_type = 'alert-danger';
    }
    if (request.getResponseHeader("X-Message-Type").indexOf("keep") !== 0) {
        if (msg) {
            $("#flash_hook").replaceWith("<div id='flash_hook'> <p>&nbsp;</p> <div class='row'> <div class='span10 offset1'> <div class='alert " + alert_type + "'> <button type='button' class='close' data-dismiss='alert'>&times;</button>" + msg + "</div> </div> </div> </div>");
        }
        if (!msg) {
            return $("#flash_hook").replaceWith("<div id='flash_hook'></div>");
        }
    }
});








